import React, { Fragment, useState, useEffect} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import five from '../assets/images/job-search/5.jpg';
import Select from 'react-select'
import InputRange from "react-input-range";
import { Container, Row, Col, Card, CardBody, CardHeader, Media, Button, Form, FormGroup, Label, Input} from 'reactstrap'
import { Cancel } from "../constant";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { get, postWithDataMultiform } from '../services/api'

const EditPosition = (props) => {

    let { id_position } = useParams();
    const { register, handleSubmit, formState: { isDirty, isValid, errors } } = useForm(
        { mode: "all", shouldUseNativeValidation: true });

    const [listTechnologiesData, setListTechnologiesData] = useState([]);
    const [listTechnologiesDataShow, setListTechnologiesDataShow] = useState([]);
    const [listTechnologiesDataShowReserv, setListTechnologiesDataShowReserv] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});
    const [listClientsData, setListClientsData] = useState([]);

    const [listSubStatus, setListSubStatus] = useState([]);
    const [selectedSubStatus, setSelectedSubStatus] = useState({});

    const [position, setPosition] = useState({});
    const [positionId, setPositionId] = useState();
    const [priorityValue, setPriorityValue] = useState();

    const [visibleTime, setVisibleTime] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [multipleTecMin, setMultipleTecMin] = useState([]);
    const [multipleTecDes, setMultipleTecDes] = useState([]);
    const [multipleClient, setMultipleClient] = useState();
    const [multipleType, setMultipleType] = useState();

    const [dateIntensiveBegin, setDateIntensiveBegin] = useState();
    const [dateIntensiveBeginDB, setDateIntensiveBeginDB] = useState();
    const [dateIntensiveEnd, setDateIntensiveEnd] = useState();
    const [dateIntensiveEndDB, setDateIntensiveEndDB] = useState();

    const [dateHorarioBegin, setDateHorarioBegin] = useState();
    const [dateHorarioBeginDB, setDateHorarioBeginDB] = useState();
    const [dateHorarioEnd, setDateHorarioEnd] = useState();
    const [dateHorarioEndDB, setDateHorarioEndDB] = useState();

    const [multipleMainMarket, setMultipleMainMarket] = useState([]);
    const [listMainMarketShow, setListMainMarketShow] = useState([]);

    const [multipleResponsable, setMultipleResponsable] = useState([]);
    const [responsableClient, setResponsableClient] = useState()
    const [listResponsableDataShow, setListResponsableDataShow] = useState([]);
    const [listResponsableData, setListResponsableData] = useState([]);

    const [listTracking, setListTracking] = useState([]);

    const [multiplePriority, setMultiplePriority] = useState();
    const [typeValue, setTypeValue] = useState();
    const [clientValue, setClientValue] = useState();
    const [freelanceValue, setFreelanceValue] = useState();
    const [isPortalValue, setIsPortalValue] = useState();
    const [mainMarketValue, setMainMarketValue] = useState();
    const [remoteWorkValue, setRemoteWorkValue] = useState();
    const [intensiveDay, setIntensiveDay] = useState();
    const idPosition = id_position;
    let idClient;
    let idStatus;
    let idSubStatus;
 
    const initialSliderRange = {
        value: 500,
        nagative: -10,
        foramtedLabel: 10,
        disabled: 10,
        foramtedLabelKg: 500,
        draggable: {
            min: 15000,
            max: 30000,
        },
    };
    const [valuesRange, setValuesRange] = useState(initialSliderRange);

    const initialSliderRangeFee = {
        value: 15,
        nagative: -5,
        foramtedLabel: 5,
        disabled: 5,
        foramtedLabelKg: 5,
        draggable: {
            min: 15,
            max: 40,
        },
    };
    const [valuesRangeFee, setValuesRangeFee] = useState(initialSliderRangeFee);
    /*
    const [multiplePosition, setMultiplePosition] = useState(null);
    const [multipleTec, setMultipleTec] = useState([]);
    const [multipleCategory, setMultipleCategory] = useState([]);
    const [multipleGrade, setMultipleGrade] = useState([]);
    const [multipleLang, setMultipleLang] = useState(null);
    const [startDate, setStartDate] = useState(null);*/

    useEffect(() => {
        getPosition();
        getListTechnologies();
        getListMainMarket();
        getTracking();
        getListResponsable();
    }, [0])

    const tableColumns = [
        {
            name: 'Fecha',
            selector: 'created_at',
            sortable: true,
            center: true,
        },
        {
            name: 'Usuario',
            selector: 'name_user',
            sortable: true,
            center: true,
        },
        {
            name: 'Candidato',
            selector: 'name_candidate',
            sortable: true,
            center: true,
        },
        {
            name: 'Accion',
            selector: 'action',
            sortable: true,
            center: false,
            minWidth: '200px'
        },
        {
          name: 'Cliente',
          selector: 'name_cliente',
          sortable: true,
          center: false,
          minWidth: '100px'
        }
      ]

    const getListResponsable = async () => {
        let dataRes = [];
        try {
            get("api/user/list/active").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name_user)
                    });
                    setListResponsableData(result.data)
                    setListResponsableDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getPosition = async () => {
        try {
            get("api/position/get/" + idPosition).then((result) => {
                if (result.success == true) {
                    idClient = result.data.client_id;
                    if(result.data.responsable != null || result.data.responsable != ''){
                        setMultipleResponsable((result.data.responsable).split(','))
                    }else{
                        setMultipleResponsable([])
                    }
                    setPositionId(result.data.id)
                    setMultipleClient(result.data.client_id);
                    setPosition(result.data);
                    setPriorityValue({ value: result.data.priority, label: result.data.priority });
                    setMultiplePriority(result.data.priority);
                    setMainMarketValue({ value: result.data.main_market_id, label: result.data.name_main_market });
                    
                    setResponsableClient(result.data.responsable_client)
                
                    result.data.freelance === 1 ? setFreelanceValue(true) : setFreelanceValue(false);
                    result.data.is_portal === 1 ? setIsPortalValue(true) : setIsPortalValue(false);
                    result.data.remote_work === 1 ? setRemoteWorkValue(true) : setRemoteWorkValue(false);
                    result.data.intensive_day === 1 ? setIntensiveDay(true) : setIntensiveDay(false);

                    console.log(multipleResponsable)

                    if(result.data.intensive_begin !== null){
                        const fechaInicio = new Date(result.data.intensive_begin);
                        const fechaFin = new Date(result.data.intensive_end);

                        setDateIntensiveBegin(fechaInicio)
                        setDateIntensiveBeginDB(result.data.intensive_begin);
                        setDateIntensiveEnd(fechaFin)
                        setDateIntensiveEndDB(result.data.intensive_end);
                    }

                    if(result.data.schedule_begin !== null){
                        const horaInputBegin = result.data.schedule_begin;
                        const horaInputEnd = result.data.schedule_end;
                        
                        const fecha = new Date();
                        const [horas1, minutos1] = horaInputBegin.split(':').map(Number);
                        const [horas2, minutos2] = horaInputEnd.split(':').map(Number);
                        let horarioBegin = fecha.setHours(horas1, minutos1, 0);
                        let horarioEnd = fecha.setHours(horas2, minutos2, 0);
                        
                        setDateHorarioBegin(horarioBegin)
                        setDateHorarioBeginDB(result.data.schedule_begin);
                        setDateHorarioEnd(horarioEnd)
                        setDateHorarioEndDB(result.data.schedule_end);
                    }

                    if(result.data.type_contract != "null"){
                        setTypeValue({ value: result.data.type_contract, label: result.data.type_contract });
                        setMultipleType(result.data.type_contract);
                    }
                    if(result.data.technology_desirable)
                        setMultipleTecDes(result.data.technology_desirable.split(",").map(str => parseInt(str)));
                    if(result.data.technology_minimal)
                        setMultipleTecMin(result.data.technology_minimal.split(",").map(str => parseInt(str)));
                    let range = [];
                    if(result.data.range_salary){
                        range = result.data.range_salary.split('-').map(Number);
                        setValuesRange({ ...valuesRange, draggable: {min: range[0], max: range[1] }});
                    }
                    if(result.data.range_salary){
                        range =  result.data.range_fee.split('-').map(Number);
                        setValuesRangeFee({ ...valuesRangeFee, draggable: {min: range[0], max: range[1] }});
                    }
                    getSubStatus(result.data.state_position_id)
                    idStatus = result.data.state_position_id;
                    idSubStatus = result.data.sub_state_position_id;
                }
                getListClients();
                getListStatus();
            });

        } catch (error) {
            console.log(error);
        }
    }

    const getListTechnologies = async () => {
        let dataRes = [];
        try {
            get("api/technology/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListTechnologiesData(result.data);
                    setListTechnologiesDataShow(dataRes);
                    setListTechnologiesDataShowReserv(dataRes);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListClients = async () => {
        let dataRes = [];
        try {
            get("api/client/list").then((result) => {
                if (result.success == true) {
                    if (idClient != null) {
                        result.data.forEach(element => {
                            dataRes.push({ value: element.id, label: element.name })
                            if (idClient == element.id) {
                                setClientValue({ value: element.id, label: element.name });
                            }
                        });
                    }
                    setListClientsData(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListMainMarket = async () => {
        try {
            let dataListMainMarketShow = [];
            get('api/mainmarket/list').then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListMainMarketShow.push(item);
                    });
                    setListMainMarketShow(dataListMainMarketShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListStatus = async () => {
        let dataRes = [];
        try {
            get("api/position/state/list").then((result) => {
                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({value: element.id, label: element.value});
                        if(element.id === idStatus) {
                            setSelectedStatus({value: element.id, label: element.value});
                        }
                    });
                    setListStatus(dataRes);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getSubStatus = async (id_state) => {
        let dataRes = [];
        try {
            get("api/position/state/sub/list/"+id_state).then((result) => {
                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({value: element.id, label: element.value});
                        if(element.id === idSubStatus) {
                            setSelectedSubStatus({value: element.id, label: element.value});
                        }
                    });
                    setListSubStatus(dataRes);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }


    const handleSelectTecMin = (selectedOption) => {
        // setMultipleTecMin(selectedOption);
        // setListTechnologiesDataShowReserv(listTechnologiesDataShow);
        setMultipleTecMin(selectedOption.map(item => item.id));

    };

    const handleSelectTecDes = (selectedOption) => {
        // setMultipleTecDes(selectedOption);
        // setListTechnologiesDataShowReserv(listTechnologiesDataShow);
        setMultipleTecDes(selectedOption.map(item => item.id));
    };

    const getTracking = async () => {
        try {
            let dataList = [];
            get('api/position/tracking/'+idPosition).then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = {
                            "candidate_id": element.candidate_id,
                            "name_candidate": element.name_candidate+' '+element.lastname_candidate+' '+element.s_lastname_candidate,
                            "name_client": element.name_client,
                            "name_user" : element.name_user,
                            "action" : element.action,
                            "created_at" : ((element.created_at).substring(0,19)).replace('T',' ')
                        };
                        dataList.push(item);
                        console.log(element)
                    });
                    setListTracking(dataList);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    // const handleInputChange = (inputValue) => {
    //     //Para refrescar las opciones y que no salgan strings en las opciones (j, ja, jav, java)
    //     setListTechnologiesDataShow(listTechnologiesDataShowReserv);
    //     // Si el usuario ha ingresado uns tecnología que no está en la lista, mostrarlo en el dropdown
    //     if (inputValue && !listTechnologiesDataShow.includes(inputValue)) {
    //         setListTechnologiesDataShow((prevList) => [...prevList, inputValue]);
    //     }
    // };

    const handleSelectClient = (data) => {
        setMultipleClient(data.value);
        setClientValue({ value: data.value, label: data.label });
    };

    const handleSelectType = (data) => {
        setTypeValue({ value: data.value, label: data.value });
        setMultipleType(data.value);

        if(data.value == "Definido"){
            setVisibleTime(true);
        }else{
            setVisibleTime(false); 
        }
    };

    const handleSelectMainMarket = (data) => {
        setMultipleMainMarket(data.value)
        setMainMarketValue({ value: data.value, label: data.label });
                    
    };

    const handleChangeDateIntensiveBegin = date => {
        setDateIntensiveBegin(date)

        let fecha = new Date(date);
        let year = fecha.getFullYear();
        let month = String(fecha.getMonth() + 1).padStart(2, "0");
        let day = String(fecha.getDate()).padStart(2, "0");
        let fechaFormateada = `${year}-${month}-${day}`;

        setDateIntensiveBeginDB(fechaFormateada)
    };

    const handleChangeDateIntensiveEnd = date => {
        setDateIntensiveEnd(date)

        let fecha = new Date(date);
        let year = fecha.getFullYear();
        let month = String(fecha.getMonth() + 1).padStart(2, "0");
        let day = String(fecha.getDate()).padStart(2, "0");

        let fechaFormateada = `${year}-${month}-${day}`;

        setDateIntensiveEndDB(fechaFormateada)
    };

    const handleChangeDateBegin = date => {
        setDateHorarioBegin(date)

        let hora = date.getHours();
        let minutos = date.getMinutes();

        let horaFormateada = `${hora}:${minutos}`;
        setDateHorarioBeginDB(horaFormateada)
    };

    const handleChangeDateEnd = date => {
        setDateHorarioEnd(date)

        let hora = date.getHours();
        let minutos = date.getMinutes();

        let horaFormateada = `${hora}:${minutos}`;
        setDateHorarioEndDB(horaFormateada)
    };

    const handleSelectPriority = (data) => {
        setPriorityValue({ value: data.value, label: data.value });
        setMultiplePriority(data.value);
    };

    const handleSelectState = (data) => {
        setSelectedSubStatus({ value: '', label: '' });

        setSelectedStatus({ value: data.value, label: data.label });

        getSubStatus(data.value)
    };

    const handleSelectSubState = (data) => {
        setSelectedSubStatus({ value: data.value, label: data.label });

      };

    const handleSelectResponsable = (data) => {
        setMultipleResponsable(data)
    };

    const onSubmit = (data, e) => {
        e.preventDefault();
        setLoading(true);
        setDisabledButton(true);

        let dataInsert = {
            ...data,
            id: positionId,
            client_id: multipleClient,
            type_contract: multipleType,
            priority: multiplePriority,
            range_salary: valuesRange.draggable.min + "-" + valuesRange.draggable.max,
            range_fee: valuesRangeFee.draggable.min + "-" + valuesRangeFee.draggable.max,
            technology_desirable: multipleTecDes.join(),
            technology_minimal: multipleTecMin.join(),
            state_position_id: selectedStatus.value,
            sub_state_position_id: selectedSubStatus.value,
            schedule_begin: dateHorarioBeginDB,
            schedule_end: dateHorarioEndDB,
            intensive_begin: dateIntensiveBeginDB,
            intensive_end: dateIntensiveEndDB,
            main_market: multipleMainMarket,
            responsable : multipleResponsable
        }

        
        console.log(dataInsert);

        try {
            postWithDataMultiform("api/position/edit", dataInsert).then((result) => {
                if (result.success === true) {
                    e.target.reset();
                    toast.success("Vacante editada correctamente.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                    setLoading(false);
                    setDisabledButton(false);
                }
            });
        } catch(e) {
            errors.showMessages();
            setLoading(false);
            setDisabledButton(false);
        }
     
    };

    const optionsTypeContract = [
        { value: 'Definido', label: 'Definido' },
        { value: 'Indefinido', label: 'Indefinido' },
    ]

    const optionsPriority = [
        { value: 'Muy Alta', label: 'Muy Alta' },
        { value: 'Alta', label: 'Alta' },
        { value: 'Media', label: 'Media' },
        { value: 'Baja', label: 'Baja' },
        
    ]


    return (
        <Fragment>
            <Breadcrumb parent="Vacantes" title="Editar Vacante" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={five} alt="" />
                                    <Media body>
                                        <h6 className="f-w-600">{"Vacante"}</h6>
                                        <p>{"Ingresa los datos de la Vacante"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                <div className="job-description">
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="client">{"Cliente/Marca"}:<span className="font-danger">*</span></Label>
                                                    <Select onChange={handleSelectClient}
                                                        options={listClientsData} id="client" name="client" value={clientValue} innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.number_identity && 'Selecciona un Cliente'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Puesto Vacante"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="name" name="name" type="text" placeholder="" defaultValue={position.name || ''} innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.name && 'Verifica el Nombre de la Vacante'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="main_market">{"Mercado Principal"}:</Label>
                                                    <Select
                                                        onChange={handleSelectMainMarket}
                                                        options={listMainMarketShow}
                                                        id="main_market" name="main_market"
                                                        value={mainMarketValue} 
                                                        innerRef={register({ required: true })} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Responsable"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="responsable"
                                                        labelKey="responsable"
                                                        onChange={handleSelectResponsable}
                                                        multiple={true}
                                                        selected={listResponsableDataShow?.filter(item => multipleResponsable.includes(item))}
                                                        options={listResponsableDataShow}
                                                        placeholder=""
                                                        innerRef={register()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="responsable_client">{"Responsable Cliente"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="responsable_client" name="responsable_client" type="text" placeholder="" defaultValue={responsableClient} innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.responsable_client && 'Verifica el Responsable Cliente'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Funciones/Responsabilidades"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="description" rows="3" defaultValue={position.description || ''} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 className="mb-0">{"Formación"} </h6>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Deseable"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="desirable" rows="3" defaultValue={position.desirable || ''} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Indispensable"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="indispensable" rows="3" defaultValue={position.indispensable || ''} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 className="mb-0">{"Requisitos"} </h6>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Requeridos Descripción: "}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="required_requirements" defaultValue={position.required_requirements || ''} rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Deseables Descripción: "}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="desirable_requirements" defaultValue={position.desirable_requirements || ''} rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="col-form-label pt-0">{"Requeridos"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="id"
                                                        labelKey="name"
                                                        onChange={handleSelectTecMin}
                                                        multiple={true}
                                                        options={listTechnologiesData}
                                                        placeholder=""
                                                        selected={listTechnologiesData?.filter(item => multipleTecMin.includes(item.id))} 
                                                        // onInputChange={handleInputChange} // Para capturar cambios en el input por si es una nueva Tecnología
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <div className="col-form-label pt-0">{"Deseables"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="id"
                                                        labelKey="name"
                                                        onChange={handleSelectTecDes}
                                                        multiple={true}
                                                        options={listTechnologiesData}
                                                        placeholder=""
                                                        selected={listTechnologiesData?.filter(item => multipleTecDes.includes(item.id))} 
                                                        // onInputChange={handleInputChange} // Para capturar cambios en el input por si es una nueva Tecnología
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label htmlFor="range_salary">{"Rango Salarial"}:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <InputRange
                                                        draggableTrack
                                                        maxValue={80000}
                                                        minValue={10000}
                                                        step={500}
                                                        value={valuesRange.draggable}
                                                        onChange={(value) =>
                                                            setValuesRange({ ...valuesRange, draggable: value })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label htmlFor="range_fee">{"Rango de Tarifa"}:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <InputRange
                                                        draggableTrack
                                                        maxValue={100}
                                                        minValue={10}
                                                        step={5}
                                                        value={valuesRangeFee.draggable}
                                                        onChange={(value) =>
                                                            setValuesRangeFee({ ...valuesRangeFee, draggable: value })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="type_contrat">{"Duración"}:</Label>
                                                    <Select 
                                                    onChange={handleSelectType} 
                                                    options={optionsTypeContract} 
                                                    id="type_contrat" name="type_contrat" 
                                                    value={typeValue} 
                                                    innerRef={register()} />

                                                    {/* <span style={{ color: "red" }}>{errors.number_identity && 'Verifica el número de documento'}</span> */}
                                                    <br/>
                                                    <div className={`content ${visibleTime ? 'visible' : 'hidden'}`}>
                                                        <Label htmlFor="type_contrat">{"¿Cuanto tiempo?"}:</Label>
                                                        <Input
                                                            type="text"
                                                            name="timeContract"
                                                            placeholder="Ingrese el tiempo"
                                                            innerRef={register()}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="colaborar_para_ioon" name="colaborar_para_ioon" type="checkbox" defaultChecked={freelanceValue} innerRef={register()} />
                                                        <Label for="colaborar_para_ioon">{"¿Puede colaborar con autónomos contratados por IOON?"}</Label>
                                                    </div>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="remote_work" name="remote_work" type="checkbox" defaultChecked={remoteWorkValue} innerRef={register()} />
                                                        <Label for="remote_work">{"Teletrabajo"}</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="is_portal" name="is_portal" type="checkbox" defaultChecked={isPortalValue} innerRef={register()} />
                                                        <Label for="is_portal">{"¿Es portal?"}</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Horario"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="schedule" rows="3" innerRef={register()} defaultValue={position.schedule || ''} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="6">
                                                                <div className="col-form-label pt-0">{"Hora inicio:"}</div>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="col-form-label pt-0">{"Hora fin:"}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                                <FormGroup>
                                                                    <DatePicker 
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={15} // Opcional: intervalo de tiempo en minutos
                                                                    timeCaption="Hora"
                                                                    selected={dateHorarioBegin}
                                                                    className="form-control" 
                                                                    timeFormat="HH:mm" // Formato de hora (en formato de 24 horas)
                                                                    dateFormat="hh:mm aa"
                                                                    onChange={handleChangeDateBegin} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="6">
                                                                <FormGroup>
                                                                    <DatePicker 
                                                                     showTimeSelect
                                                                     showTimeSelectOnly
                                                                     timeIntervals={15} // Opcional: intervalo de tiempo en minutos
                                                                     timeCaption="Hora"
                                                                    selected={dateHorarioEnd} 
                                                                    className="form-control" 
                                                                    timeFormat="HH:mm" // Formato de hora (en formato de 24 horas)
                                                                    dateFormat="hh:mm aa"
                                                                    onChange={handleChangeDateEnd} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="checkbox checkbox-dark m-squar">
                                                    <FormGroup>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<Input id="intensive_day" name="intensive_day" type="checkbox" defaultChecked={intensiveDay} innerRef={register()} />
                                                        <Label for="intensive_day">{"Jornada Intensiva"}</Label>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col sm="6">
                                                        <div className="col-form-label pt-0">{"Fecha inicio:"}</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="col-form-label pt-0">{"Fecha fin:"}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <DatePicker 
                                                            selectsStart
                                                            startDate={dateIntensiveBegin}
                                                            endDate={dateIntensiveEnd} 
                                                            selected={dateIntensiveBegin} 
                                                            className="form-control" 
                                                            onChange={handleChangeDateIntensiveBegin} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <DatePicker 
                                                            selectsEnd
                                                            startDate={dateIntensiveBegin}
                                                            endDate={dateIntensiveEnd} 
                                                            selected={dateIntensiveEnd} 
                                                            className="form-control" 
                                                            onChange={handleChangeDateIntensiveEnd} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Comentario"}</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="comment" rows="3" innerRef={register()} defaultValue={position.comment || ''} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="priority">{"Prioridad"}:</Label>
                                                    <Select onChange={handleSelectPriority} options={optionsPriority} id="priority" name="priority" value={priorityValue} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Archivo"}:</Label>
                                                    <Input className="form-control" type="file" name="file" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="state">{"Estado de la Vacante"}:</Label>
                                                    <Select onChange={handleSelectState} options={listStatus} id="state" name="state" value={selectedStatus} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="state">{"Sub Estado de la Vacante"}:</Label>
                                                    <Select onChange={handleSelectSubState} options={listSubStatus} id="sub_state" name="sub_state" value={selectedSubStatus} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button disabled={disabledButton} color="primary mr-1" type="submit">{"Guardar Cambios"}</Button>
                                            <Button type='button' disabled={disabledButton} onClick={() => {window.location.href = '/position/list' }} color="light">{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                                {<div className={`content ${loading ? 'visible loader-box' : 'hidden'}`}><div className="loader-8"></div></div>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardBody>
                                <h6 className="f-w-600">{"Historial de la Vacante"}</h6>
                                    <DataTable className="table-responsive"
                                    columns={tableColumns}
                                    data={listTracking}
                                    persistTableHead
                                    searchable={true}
                                    pagination
                                    noDataComponent={<div>No hay datos disponibles</div>}
                                    progressPending={loading}
                                    progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                                    />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default EditPosition;