import React, { Fragment,useState, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import ApexCharts from 'react-apexcharts'
import Slider from "react-slick";
import {Container,Row,Col,Card,CardBody,CardHeader,Table,Button} from 'reactstrap' 
import CountUp from 'react-countup';
//import Chart from 'react-apexcharts'
import Select from 'react-select'
import {ShoppingBag, TrendingUp, Navigation, Italic, Send, Filter, Watch, Flag, UserPlus, UserX, UserCheck, File} from 'react-feather'
import {Monthlysales,columnCharts,totalearning,Riskfactorchart} from './chartsData/apex-charts-data'
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import Chart from "react-google-charts";
import { Field, formatQuery, RuleGroupType } from 'react-querybuilder';
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import loadingGifCard from '../../assets/images/loadingDashboard.gif';
import CardStatus from '../cards/cardStatus';

import {NewProduct,NewsUpdate,RiskFactor,BestSeller,Location,TodayTotalSale,TodayTotalVisits,OurSaleValue,New,Hot,TotalProfit,HikeShoes,CouponCode,TreePot,TShirt,TotalGoal,GoalArchive,Duration,DownloadDetails,Johnketer,HerryVenter,Done,Pending,LoainDeo,TodayStockValue,Bag,HorenHors,InProcess,FenterJessy,Success} from '../../constant'
import DataTable from 'react-data-table-component'
import { postWithData, get } from '../../services/api'

const RRHH = (props) => {

  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));

  const [loading, setLoading] = useState(true);

  const [listEficiencia, setListEficiencia] = useState([]);

  const [year, setYear] = useState('');
  const [yearBack, setYearBack] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextClientMercado, setSearchTextClientMercado] = useState('');
  
  const [N_RecursosSolicitados, setN_RecursosSolicitados] = useState('');
  const [N_CV_presentados, setN_CV_presentados] = useState('');
  const [N_CV_Entrevistados, setN_CV_Entrevistados] = useState('');
  const [CV_Optimos, setCV_Optimos] = useState('');
  const [N_Incorporados, setN_Incorporados] = useState('');
  const [SR_Incorporados, setSR_Incorporados] = useState('');
  const [Indicador_Eficiencia, setIndicador_Eficiencia] = useState('');

  const [pipeLine, setPipeLine] = useState('');
  const [Opor_ganadas, setOpor_ganadas] = useState('');
  const [Opor_perdidas, setOpor_perdidas] = useState('');
  const [Porc_Ganadas, setPorc_Ganadas] = useState('');
  const [Porc_Perdidas, setPorc_Perdidas] = useState('');

  const [listIndicatorRechazo, setListIndicatorRechazo] = useState([]);
  const [listIndicatorReserva, setListIndicatorReserva] = useState([]);
  const [listClientesData, setListClientesData] = useState([]);

  const [listRechazoGrafica, setListRechazoGrafica] = useState([]);
  const [listMercadoGrafica, setListMercadoGrafica] = useState([]);

  const [listIndicatorRevenue, setListIndicatorRevenue] = useState([]);

  const [listIndicatorMercado, setListIndicatorMercado] = useState([]);
  const [listIndicatorMercadoReserva, setListIndicatorMercadoReserva] = useState([]);

  const [countCandidatesNew, setCountCandidatesNew] = useState(0);
  const [countCandidatesEntrevistados, setCountCandidatesEntrevistados] = useState(0);
  const [countCandidateCVEnviados, setCountCandidateCVEnviados] = useState(0);
  const [countCandidatePendientesNeg, setCountCandidatePendientesNeg] = useState(0);
  const [countCandidatesAp, setCountCandidatesAp] = useState(0);
  const [countCandidatesRe, setCountCandidatesRe] = useState(0);

  const [isLoadingEnv, setIsLoadingEnv] = useState(false);
  const [isLoadingEntr, setIsLoadingEntr] = useState(false);
  const [isLoadingApro, setIsLoadingApro] = useState(false);
  const [isLoadingNeg, setIsLoadingNeg] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [isLoadingRec, setIsLoadingRec] = useState(false);

  const [dataCountRecruiter, setDataCountRecruiter] = useState([]);
  
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Agrega un 0 al principio si el mes es menor a 10
  const currentDay = String(today.getDate()).padStart(2, '0'); // Agrega un 0 al principio si el día es menor a 10


  const initialQuery = { 
    combinator: 'and', 
    rules: [
      /*{
        field: 'positions.client_id',
        valueEditorType: 'select',
        operator: "=",
      },
      {
        field: 'CAST(tracking_candidates.date_register AS DATE)',
        value: `${currentYear}-${currentMonth}-${currentDay},${currentYear}-${currentMonth}-${currentDay}`,
        operator: "between"
      },*/
    ]
  };
  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {

    getIndicators();
    getListClients();
    getDataCountRecriiter();

  }, [])

  const fields = [
    {
      name: 'CAST(tracking_candidates.date_register AS DATE)',
      label: 'Fecha de Registro',
      inputType: 'date'
    },
    {
      name: 'positions.client_id',
      label: 'Cliente',
      valueEditorType: 'select',
      operator: "=",
      values: listClientesData
    }
  ];

  const getIndicators = async () => {
    buscar();
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Simular un clic en el botón "Buscar"
      buscar();
    }
  };

  const buscar = () => {
    let queryWhereSql = formatQuery(query, 'sql');
    console.log("query", query)

    //Client id
    let dataClient = (queryWhereSql.split("client_id = '")[1]);

    let queryResult = queryWhereSql

    console.log(dataClient)
    let data = {
      query: queryResult,
      dataCliente: (dataClient == undefined)? 0 : dataClient
    }

    try {
      let dataList = [];
      let dataMercado = [];
      let dataGraficaRechazo = [];
      let dataGraficaMercado = [];

      postWithData("api/dashboardrrhh/indicator", data).then((result) => {
          //Indicador Rechazo  
          result.data.indicador_rechazos.forEach(element => {
            
            let nameEstadoMotivo = '';
            let conteoRechazo = '';

            switch (element.marca) {
              case "x":
                nameEstadoMotivo = <p style={{fontSize:12, paddingLeft:50 }}>{element.NAME}</p>
                conteoRechazo = element.conteo;
                break;
              case "y":
                nameEstadoMotivo = <p style={{fontSize:14, fontWeight:600 }}>{element.NAME}</p>
                conteoRechazo = '';
                break;
              case "z":
                nameEstadoMotivo = <p style={{fontSize:12, fontWeight:500, fontStyle: 'italic' }}>{element.NAME}</p>
                conteoRechazo = '';
                break;
              case "title":
                nameEstadoMotivo = <p style={{fontSize:16, fontWeight:700 }}>{element.NAME}</p>
                conteoRechazo = '';
                break;
            }
           
            dataList.push({ 
                name: nameEstadoMotivo, 
                conteo: conteoRechazo,
                id_status: element.id_status,
                id_motivo: element.id_motivo,
                marca: element.marca,
              })
                
          });
          setListIndicatorRechazo(dataList)

          //Grafica de Rechazo
          dataGraficaRechazo.push(['Task', 'Hours per Day'])
          result.data.grafica_rechazo.forEach(element => {
            dataGraficaRechazo.push([element.name_status, element.conteo])
          });
          setListRechazoGrafica(dataGraficaRechazo);

          //Indicador Mercado Principal
          result.data.indicador_mercado.forEach(element => {
        
            let nameMercado = '';
            let conteoMercado = '';

            switch (element.marca) {
              case "merc":
                nameMercado = <p style={{fontSize:12, paddingLeft:50 }}>{element.NAME}</p>
                conteoMercado = element.conteo;
                break;
              case "clie":
                nameMercado = <p style={{fontSize:14, fontWeight:600 }}>{element.NAME}</p>
                conteoMercado = '';
                break;
              
            }
           
            dataMercado.push({ 
                name: nameMercado, 
                conteo: conteoMercado,
                id_cliente: element.id_cliente,
                marca: element.marca,
              })
                
          });
          setListIndicatorMercado(dataMercado)

          //Grafica de Mercado
          dataGraficaMercado.push(['Task', 'Hours per Day'])
          result.data.grafica_mercado.forEach(element => {
            dataGraficaMercado.push([element.mercado, element.conteo_mercado])
          });
          setListMercadoGrafica(dataGraficaMercado)

          setLoading(false)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const getDataCountRecriiter = () => {
    
    try {
      let dateConteo = [];

      get("api/dashboardrrhh/indicator/recruiter").then((result) => {
          //console.log(result.data)
          
          result.data.forEach(element => {
            console.log(element)
            dateConteo.push(element)
                
          });
          setDataCountRecruiter(dateConteo)
          

      });
    } catch (error) {
      console.log(error)
    }
  };

  const tableColumns = [
    {
      name:'Estado / Motivo',
      selector:'name',
      sortable:false,
      //center:true
      minWidth: '420px'
    },
    {
      name: 'Conteo',
      selector: 'conteo',
      sortable: false,
      center:true,
    },
    
  ]

  const tableColumnsMercadi = [
    {
      name:'Cliente / Mercado',
      selector:'name',
      sortable:false,
      //center:true
    },
    {
      name: 'Conteo',
      selector: 'conteo',
      sortable: false,
      center:true,
    },
    
  ]

  const tableColumnsCountRecruiter = [
    {
      name:'Recruiter',
      selector:'recruiter_name',
      sortable:true,
      minWidth: '200px'
    },
    {
      name: 'Nuevos',
      selector: 'count_new',
      conditionalCellStyles: [
        {
          when: row => row.count_new >= 0 && row.count_new <= 7,
          style: {
            backgroundColor: '#ff6961',
            //color: '#155724',
          },
        },
        {
          when: row => row.count_new >= 8 && row.count_new <= 14,
          style: {
            backgroundColor: '#fdff5c',
            //color: '#721c24',
          },
        },
        {
          when: row => row.count_new >= 15,
          style: {
            backgroundColor: '#82ff75',
            //color: '#856404',
          },
        },
      ],
      sortable: true,
      center:true,
    },
    {
      name: 'Enviados',
      selector: 'count_sent',
      sortable: true,
      center:true,
    },
    {
      name: 'Citados',
      selector: 'count_citado',
      sortable: true,
      center:true,
    },
    {
      name: 'Entrevistados',
      selector: 'count_interviewed',
      sortable: true,
      center:true,
    },
    {
      name: 'Rechazados',
      selector: 'count_rejected',
      sortable: true,
      center:true,
    },
    {
      name: 'Negociación',
      selector: 'count_negotiation',
      sortable: true,
      center:true,
    },
    {
      name: 'Aceptados',
      selector: 'count_accepted',
      sortable: true,
      center:true,
    },
    //Semanal
    {
      name: 'Nuevos (Semana)',
      selector: 'count_new_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Enviados (Semana)',
      selector: 'count_sent_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Citadoss (Semana)',
      selector: 'count_citado_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Entrevistados (Semana)',
      selector: 'count_interviewed_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Rechazados (Semana)',
      selector: 'count_rejected_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Negociación (Semana)',
      selector: 'count_negotiation_week',
      sortable: true,
      center:true,
    },
    {
      name: 'Aceptados (Semana)',
      selector: 'count_accepted_week',
      sortable: true,
      center:true,
    },
    //Mensual
    {
      name: 'Nuevos (Mensual)',
      selector: 'count_new_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Enviados (Mensual)',
      selector: 'count_sent_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Citados (Mensual)',
      selector: 'count_citado_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Entrevistados (Mensual)',
      selector: 'count_interviewed_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Rechazados (Mensual)',
      selector: 'count_rejected_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Negociación (Mensual)',
      selector: 'count_negotiation_month',
      sortable: true,
      center:true,
    },
    {
      name: 'Aceptados (Mensual)',
      selector: 'count_accepted_month',
      sortable: true,
      center:true,
    },
  ]

  const getListClients = async () => {
    let dataRes = [];
    try {
        get("api/client/list").then((result) => {
            if (result.success == true) {
                dataRes.push({ name: '', label: 'Seleccione Cliente' })
                result.data.forEach(element => {
                    dataRes.push({ name: element.id, label: element.name })
                });
                
                setListClientesData(dataRes)
            }
        });
    } catch (error) {
        console.log(error)
    }
  }


    return (
        <Fragment>
          <Breadcrumb parent="Dashboard" title="RRHH" />
            <Container fluid={true}>
            {dataCountRecruiter.map((row, index) => {
              return (
                <CardStatus 
                  nameRecruiter={row.recruiter_name}
                  isLoadingNew={false}
                  countCandidatesNew={row.count_new}
                  isLoadingEnv={false}
                  countCandidateCVEnviados={row.count_sent}
                  isLoadingEntr={false}
                  countCandidatesCitados={row.count_citado}
                  countCandidatesEntrevistados={row.count_interviewed}
                  isLoadingRec={false}
                  countCandidatesRe={row.count_rejected}
                  isLoadingNeg={false}
                  countCandidatePendientesNeg={row.count_negotiation}
                  isLoadingApro={false}
                  countCandidatesAp={row.count_accepted}
                  //Semanal
                  countCandidatesNew_week={row.count_new_week}
                  countCandidateCVEnviados_week={row.count_sent_week} 
                  countCandidatesCitados_week={row.count_citado_week} 
                  countCandidatesEntrevistados_week={row.count_interviewed_week} 
                  countCandidatesRe_week={row.count_rejected_week}
                  countCandidatePendientesNeg_week={row.count_negotiation_week}
                  countCandidatesAp_week={row.count_accepted_week}
                  //Mensual
                  countCandidatesNew_month={row.count_new_month}
                  countCandidateCVEnviados_month={row.count_sent_month}
                  countCandidatesCitados_month={row.count_citado_month} 
                  countCandidatesEntrevistados_month={row.count_interviewed_month} 
                  countCandidatesRe_month={row.count_rejected_month} 
                  countCandidatePendientesNeg_month={row.count_negotiation_month}
                  countCandidatesAp_month={row.count_accepted_month}
                />
              );
            })}
            <Row className="second-chart-list third-news-update">
                <Col sm="12" className="custom-col-table">
                  <Card>
                    <CardHeader className="card-no-border">
                      <h5>KPI's Gestión por Recruiter</h5>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <DataTable
                        data={dataCountRecruiter}
                        pagination
                        columns={tableColumnsCountRecruiter}
                        striped={true}
                        persistTableHead
                        searchable={true}
                        paginationPerPage={60}  
                        noDataComponent={<div>No hay datos disponibles</div>}
                        progressPending={loading}
                        progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                        customStyles={{
                          head: {
                            style: {
                              textAlign: 'center',
                            },
                          },
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div>
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    onQueryChange={(q) => setQuery(q)}
                    showCombinatorsBetweenRules
                    validator={defaultValidator}
                    controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                    onKeyPress={handleKeyPress}
                  />
                </QueryBuilderDnD>
                <br />
                <Button className="btn-search" id="btnBuscar" color="primary" onClick={buscar}>{"Actualizar"}</Button>
              </div>
              <Row className="second-chart-list third-news-update">
                <Col sm="7" className="box-col-12">
                  <Card className="o-hidden">
                      <CardHeader>
                      <h5>{"Motivos de Rechazo"}</h5>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <Row>
                            <Col xs="12">
                              <div id="chart-widget5">
                              <Chart
                                  width={'100%'}
                                  height={'320px'}
                                  chartType="PieChart"
                                  data={listRechazoGrafica}
                                  options={{
                                      colors: ["#a927f9", "#51bb25" , "#1E24EC", "#f8d62b"],
                                      is3D: true
                                  }}
                                  rootProps={{ 'data-testid': '2' }}
                              />
                              </div>
                            </Col>
                        </Row>
                      </CardBody>
                  </Card>
                </Col>
                <Col sm="5" className="box-col-12">
                  <Card className="o-hidden">
                      <CardHeader>
                      <h5>{"Indicadores Globales"}</h5>
                      </CardHeader>
                      <CardBody className="pt-0">
                        <Row>
                            <Col xs="12">
                              <div id="chart-widget5">
                              <Chart
                                  width={'100%'}
                                  height={'320px'}
                                  chartType="PieChart"
                                  data={listMercadoGrafica}
                                  options={{
                                      colors: ["#0000FF", "#A927F9", "#FF0000", "#3D8C1C" , "#4B0082", "#FF6347"],
                                      is3D: true
                                  }}
                                  rootProps={{ 'data-testid': '2' }}
                              />
                              </div>
                            </Col>
                        </Row>
                      </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="second-chart-list third-news-update">
                <Col sm="7">
                </Col>
                <Col sm="5">
                </Col>
              </Row>
              <Row className="second-chart-list third-news-update">
                <Col sm="7">
                  <Card>
                    <CardHeader className="card-no-border">
                      <h5>KPI's Motivos de Rechazo</h5>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <DataTable
                        data={listIndicatorRechazo}
                        pagination
                        columns={tableColumns}
                        striped={true}
                        persistTableHead
                        searchable={true}
                        paginationPerPage={60}  
                        noDataComponent={<div>No hay datos disponibles</div>}
                        progressPending={loading}
                        progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                        customStyles={{
                          head: {
                            style: {
                              textAlign: 'center',
                            },
                          },
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="5">
                  <Card>
                    <CardHeader className="card-no-border">
                      <h5>KPI's Mercados Principales</h5>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <DataTable
                        data={listIndicatorMercado}
                        pagination
                        columns={tableColumnsMercadi}
                        striped={true}
                        persistTableHead
                        searchable={true}
                        paginationPerPage={60}  
                        noDataComponent={<div>No hay datos disponibles</div>}
                        progressPending={loading}
                        progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                        customStyles={{
                          head: {
                            style: {
                              textAlign: 'center',
                            },
                          },
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        </Fragment>
    );
}

export default RRHH;