import React,{useEffect,Fragment} from 'react';
import {Container,Row,Col,Card,CardHeader,CardBody} from "reactstrap";
import Knob from "knob";

import {ShoppingBag, TrendingUp, Navigation, Italic, Send, Filter, Watch, Flag, UserPlus, UserX, UserCheck, File} from 'react-feather'
import loadingGifCard from '../../assets/images/loadingDashboard.gif';
import CountUp from 'react-countup';
const cardStatus = (props) => {

   const {nameRecruiter, isLoadingNew, 
    countCandidatesNew, 
    isLoadingEnv, 
    countCandidateCVEnviados, 
    isLoadingEntr, 
    countCandidatesCitados, 
    countCandidatesEntrevistados, 
    isLoadingRec, 
    countCandidatesRe, 
    isLoadingNeg, 
    countCandidatePendientesNeg, 
    isLoadingApro, 
    countCandidatesAp,
    //Semanal
    countCandidatesNew_week, 
    countCandidateCVEnviados_week, 
    countCandidatesCitados_week, 
    countCandidatesEntrevistados_week, 
    countCandidatesRe_week, 
    countCandidatePendientesNeg_week, 
    countCandidatesAp_week,
    //Mensual
    countCandidatesNew_month, 
    countCandidateCVEnviados_month, 
    countCandidatesCitados_month, 
    countCandidatesEntrevistados_month, 
    countCandidatesRe_month, 
    countCandidatePendientesNeg_month, 
    countCandidatesAp_month
  } = props;

    return (
      <div>
        <h3 className="row-title">{nameRecruiter}</h3>
        <h5 className="text-muted">{"Diario"}</h5>
        <Row className="second-chart-list third-news-update">
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesNew >= 23) ? 'background-semaforo-excelente ' : ''}${(countCandidatesNew >= 16 && countCandidatesNew <= 22) ? 'background-semaforo-bueno ' : ''}${(countCandidatesNew <= 16) ? 'background-semaforo-malo ' : ''}`
              }
            >
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus />
                  </div>
                  <div className="media-body" style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Nuevos"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNew ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesNew} />
                      )}
                    </h5>
                    <UserPlus className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidateCVEnviados >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidateCVEnviados >= 20 && countCandidateCVEnviados <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidateCVEnviados <= 20) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Send />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Enviados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEnv ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidateCVEnviados} />
                      )}
                    </h5>
                    <Send className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesCitados >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesCitados >= 11 && countCandidatesCitados <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesCitados <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Citados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesCitados} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesEntrevistados >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesEntrevistados >= 11 && countCandidatesEntrevistados <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesEntrevistados <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Entrevistados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesEntrevistados} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesRe >= 0 && countCandidatesRe <= 4) ? 'background-semaforo-malo ' : ''}${(countCandidatesRe >= 5 && countCandidatesRe <= 10) ? 'background-semaforo-bueno '  : ''}${(countCandidatesRe >= 11) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserX />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Rechazados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingRec ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesRe} />
                      )}
                    </h5>
                    <UserX className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatePendientesNeg >= 2) ? 'background-semaforo-excelente ' : ''}${(countCandidatePendientesNeg == 1) ? 'background-semaforo-bueno ' : ''}${(countCandidatePendientesNeg == 0) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Watch />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Negociación"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNeg ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatePendientesNeg} />
                      )}
                    </h5>
                    <Watch className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesAp >= 4) ? 'background-semaforo-excelente ' : ''}${(countCandidatesAp == 4) ? 'background-semaforo-bueno ' : ''}${(countCandidatesAp <= 4) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserCheck />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Aprobados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingApro ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesAp} />
                      )}
                    </h5>
                    <UserCheck className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <h5 className="text-muted">{"Semanal"}</h5>
        <Row className="second-chart-list third-news-update">
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesNew_week >= 23) ? 'background-semaforo-excelente ' : ''}${(countCandidatesNew_week >= 16 && countCandidatesNew_week <= 22) ? 'background-semaforo-bueno ' : ''}${(countCandidatesNew_week <= 16) ? 'background-semaforo-malo ' : ''}`
              }
            >
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Nuevos"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNew ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesNew_week} />
                      )}
                    </h5>
                    <UserPlus className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidateCVEnviados_week >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidateCVEnviados_week >= 20 && countCandidateCVEnviados_week <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidateCVEnviados_week <= 20) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Send />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Enviados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEnv ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidateCVEnviados_week} />
                      )}
                    </h5>
                    <Send className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesCitados_week >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesCitados_week >= 11 && countCandidatesCitados_week <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesCitados_week <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Citados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesCitados_week} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesEntrevistados_week >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesEntrevistados_week >= 11 && countCandidatesEntrevistados_week <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesEntrevistados_week <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Entrevistados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesEntrevistados_week} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesRe_week >= 0 && countCandidatesRe_week <= 4) ? 'background-semaforo-malo ' : ''}${(countCandidatesRe_week >= 5 && countCandidatesRe_week <= 10) ? 'background-semaforo-bueno '  : ''}${(countCandidatesRe_week >= 11) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserX />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Rechazados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingRec ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesRe_week} />
                      )}
                    </h5>
                    <UserX className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatePendientesNeg_week >= 2) ? 'background-semaforo-excelente ' : ''}${(countCandidatePendientesNeg_week == 1) ? 'background-semaforo-bueno ' : ''}${(countCandidatePendientesNeg_week == 0) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Watch />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Negociación"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNeg ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatePendientesNeg_week} />
                      )}
                    </h5>
                    <Watch className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesAp_week >= 4) ? 'background-semaforo-excelente ' : ''}${(countCandidatesAp_week == 4) ? 'background-semaforo-bueno ' : ''}${(countCandidatesAp_week <= 4) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserCheck />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Aprobados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingApro ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesAp_week} />
                      )}
                    </h5>
                    <UserCheck className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <h5 className="text-muted">{"Mensual"}</h5>
        <Row className="second-chart-list third-news-update">
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesNew >= 23) ? 'background-semaforo-excelente ' : ''}${(countCandidatesNew >= 16 && countCandidatesNew <= 22) ? 'background-semaforo-bueno ' : ''}${(countCandidatesNew <= 16) ? 'background-semaforo-malo ' : ''}`
              }
            >
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Nuevos"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNew ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesNew_month} />
                      )}
                    </h5>
                    <UserPlus className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidateCVEnviados_month >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidateCVEnviados_month >= 20 && countCandidateCVEnviados_month <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidateCVEnviados_month <= 20) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Send />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Enviados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEnv ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidateCVEnviados_month} />
                      )}
                    </h5>
                    <Send className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesCitados_month >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesCitados_month >= 11 && countCandidatesCitados_month <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesCitados_month <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Citados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesCitados_month} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesEntrevistados_month >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesEntrevistados_month >= 11 && countCandidatesEntrevistados_month <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesEntrevistados_month <= 10) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Filter />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Entrevistados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesEntrevistados_month} />
                      )}
                    </h5>
                    <Filter className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesRe_month >= 0 && countCandidatesRe_month <= 4) ? 'background-semaforo-malo ' : ''}${(countCandidatesRe_month >= 5 && countCandidatesRe_month <= 10) ? 'background-semaforo-bueno '  : ''}${(countCandidatesRe_month >= 11) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserX />
                  </div>
                  <div className="media-body" style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Rechazados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingRec ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesRe_month} />
                      )}
                    </h5>
                    <UserX className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatePendientesNeg_month >= 2) ? 'background-semaforo-excelente ' : ''}${(countCandidatePendientesNeg_month == 1) ? 'background-semaforo-bueno ' : ''}${(countCandidatePendientesNeg_month == 0) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Watch />
                  </div>
                  <div className="media-body" style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Negociación"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingNeg ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatePendientesNeg_month} />
                      )}
                    </h5>
                    <Watch className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" xl="2" lg="4">
            <Card
              style={{ padding: '8px' }} // Reduce padding del Card
              className={`o-hidden background-default ${
                (countCandidatesAp_month >= 4) ? 'background-semaforo-excelente ' : ''}${(countCandidatesAp_month == 4) ? 'background-semaforo-bueno ' : ''}${(countCandidatesAp_month <= 4) ? 'background-semaforo-malo ' : ''}`
              }>
              <CardBody className="b-r-4 card-body" style={{ padding: '8px' }}> {/* Reduce padding del CardBody */}
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserCheck />
                  </div>
                  <div className="media-body"  style={{ paddingLeft: '6px' }}>
                    <span className="m-0">{"Aprobados"}</span>
                    <h5 className="mb-0 counter">
                      {isLoadingApro ? (
                        <div className="loader">
                          <img src={loadingGifCard} alt="Loading..." width={20} /> {/* Ajusta el tamaño del gif de carga */}
                        </div>
                      ) : (
                        <CountUp end={countCandidatesAp_month} />
                      )}
                    </h5>
                    <UserCheck className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
}

export default cardStatus;